.court {
    margin-bottom: 30px;
}

.appeal {
    border: 1px solid var(--gray);
    padding-top: 15px;
}

.arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    margin-right: 10px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}