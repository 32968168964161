ul {
    list-style: none;
    padding-left: 0;
}

h4, h5 {
    padding: 0;
}

hr {
    border-color: var(--gray);
}

li a {
    text-decoration: underline;
}